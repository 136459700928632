import React, { Component } from 'react';
import AOS from 'aos';
import CountUp from 'react-countup';
import AnimateHeight from 'react-animate-height';

import { Layout, SEO, Image, CustomersSlider, CaseStudies, TechnologiesStack } from '../../components';
import { validateArray, validateImageCT, validateSingleImage, validateSingleLink } from '../../components/utilities/functions';

import './index.scss';

class ServiceDetailPage extends Component {

	state = {
		updateCount: false,
		height: 0,
		activeAccIndex: 0,
		showForm: false
	}

	componentDidMount() {
		AOS.init({
			duration: 1000,
			disable: 'mobile',
			once: true
		});


		if (typeof window !== "undefined") {
			window.addEventListener("scroll", this.handleScroll);
			if (document && document.getElementsByClassName("count-value")) {
				this.updateDimensions(false);
				window.addEventListener("resize", this.updateDimensions(true));
			}
		}
	}

	updateDimensions = (resize) => {

		var elements = document.getElementsByClassName("count-value"),
			allHeights = [],
			i = 0;
		if (resize === true) {
			for (i = 0; i < elements.length; i++) {
				elements[i].style.height = 'auto';
			}
		}
		for (i = 0; i < elements.length; i++) {
			var elementHeight = elements[i].clientHeight;
			allHeights.push(elementHeight);
		}
		for (i = 0; i < elements.length; i++) {
			elements[i].style.height = Math.max.apply(Math, allHeights) + 'px';
		}
	}

	accordionClicked = (index, accLength) => {
		if (this.state.activeAccIndex === index) {
			this.setState({
				activeAccIndex: index < (accLength - 1) ? index + 1 : 0
			});
		}
		else {
			this.setState({
				activeAccIndex: index
			});
		}
	}

	toggleForm = (index) => {
		this.setState({
			showForm: true
		});
	}

	handleScroll = () => {
		const currentScrollPos = typeof window !== "undefined" && window.pageYOffset;
		const stickyContainer = document.getElementById("sticky-container");
		if (
			stickyContainer
			&& stickyContainer.offsetTop &&
			currentScrollPos >= (stickyContainer.offsetTop - window.innerHeight) && !this.state.updateCount
		) {
			this.setState({
				updateCount: true
			});
		}
	};

	render() {

		const { pageContext } = this.props;
		const { uid = "", locale = "", hero, url, seo, services_info, technologies_section, we_offer, way_of_work, development_examples, clients_section, statistics_section, accordion_section } = (pageContext.node) || {};

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (

			<Layout className='service-detail-wrapper animation-wrapper' pageURL={url}>
				<SEO
					pageURL={url} {...seo}
					hasMarketo
					extension={extension}
				/>
				{
					hero &&
					<div className='service-detail-hero box-80 text-center'>
						<Image className='hero-image' data={hero.hero_image} />
						<div className='container container--980'>
							{
								hero.tag &&
								<p className='hero-tag'>{hero.tag}</p>
							}
							{
								hero.heading &&
								<h1 className='title-lineover'>{hero.heading}</h1>
							}
							{
								hero.description &&
								<div className='description p__body1' dangerouslySetInnerHTML={{ __html: hero.description }} />
							}

							{
								!this.state.showForm &&
								<button type='button' onClick={this.toggleForm} className='btn btn-primary btn-rounded'>
									{hero.cta_title || 'Get Started'}
								</button>
							}
							<div className={`text-center hero-form-wrapper ${(this.state.showForm ? 'visible' : '')}`}
								// style={{ display: (this.state.showForm ? 'block' : 'none') }}
							>
								{/* <div className='form-loader'></div> */}
								<form id="mktoForm_1074">
								</form>
								<div id="confirmform" className='thankyou-msg text-center'>
									<h3 className='msg'><strong>Thank you! Your message has been successfully sent.</strong></h3>
								</div>
							</div>
						</div>
					</div>
				}

				{
					accordion_section &&
					<div className='accordion-section box-80'>
						<div className='container container--980'>
							<div className='row'>
								<div className={' col-12 col-768-6'}>
									{
										validateImageCT(accordion_section.left_image) &&
										<div className='accordion-aside-image'>
											<Image
												className='width-100'
												data={accordion_section.left_image}
												resizeWidth={600}
												format={false}
											/>
										</div>
									}
								</div>
								<div className={'accordion col-12 col-768-6'}>
									{
										validateArray(accordion_section.accordion) &&
										accordion_section.accordion.map((item, index) => (
											<div key={index} className='accordion-box'>
												{
													item.heading &&
													<h3
														className={`accordion-title ${((this.state.activeAccIndex === index) ? 'active' : '')}`}
														onClick={() => this.accordionClicked(index, accordion_section.accordion.length)}
														data-type="title"
														dangerouslySetInnerHTML={{ __html: item.heading }} />

												}
												{
													item.description &&
													<AnimateHeight
														duration={500}
														height={((this.state.activeAccIndex === index) ? 'auto' : 0)}
													>
														<div data-type='description' className='accordion-content' dangerouslySetInnerHTML={{ __html: item.description }} />

													</AnimateHeight>
												}
											</div>
										))
									}
								</div>
							</div>
						</div>
					</div>
				}
				{
					(services_info && (services_info.heading || services_info.description )) &&
					<div className='services-info box-10080  text-center'>
						<div className='container container--980'>
							{
								services_info.heading &&
								<h2 className='title-lineover'>{services_info.heading}</h2>
							}
							{
								services_info.description &&
								<div className='author-quote p__body1' dangerouslySetInnerHTML={{ __html: services_info.description }} />
							}

						</div>
					</div>
				}
				{
					we_offer && validateArray(we_offer.services) &&
					<div className='services-offered bg-grey'>
						<div className='container'>
							{
								we_offer.heading &&
								<h2 className='title-lineover'>{we_offer.heading}</h2>
							}
							{
								validateArray(we_offer.services) &&
								<div className='serv-offered-row'>
									{
										we_offer.services.map((item, index) =>
											<div className='text-center serv-offered-col' key={index}>
												{
													item.icon &&
													<div className='icon-image'>
														<Image
															data={{ image: item.icon, image_alt: item.title }}
															format={false}
														/>
													</div>
												}
												{
													item.title &&
													<span className='icon-title'>{item.title}</span>
												}
											</div>
										)}
								</div>
							}
						</div>
					</div>
				}

				{
					way_of_work && validateArray(way_of_work.steps)  &&
					<div className='work-section has-top-border box-80'>
						<div className='container'>
							{
								way_of_work.heading &&
								<h2 className='title-linethrough width-80'>
									<span>{way_of_work.heading}</span>
								</h2>
							}
							{
								validateArray(way_of_work.steps) &&
								<div className='row wayOfWork-section'>
									{
										way_of_work.steps.map((item, index) =>
											<div key={index} className={'workstep-col text-center col-12 col-768-6 col-992-4'}>
												<div className={'workstep-card'} key={index}>
													<div className='stepicon'>
														{
															validateSingleImage(item.icon) &&
															<Image
																data={{ image: item.icon }}
																customAlt={item.icon_alt_text}
																format={false}
																resizeWidth={115}
															/>
														}
													</div>
													{
														item.title &&
														<h4 className='icon-title1'>{item.title}</h4>
													}
													{
														item.description &&
														<div className='card-description' dangerouslySetInnerHTML={{ __html: item.description }} />
													}
												</div>
											</div>
										)
									}
								</div>
							}
						</div>
					</div>
				}
				{
					statistics_section && validateArray(statistics_section.statistics) &&
					<div className='customers-section text-center bg-grey box-80 has-top-border'>
						<div id="sticky-container" />
						<div className='container'>
							{
								statistics_section.heading &&
								<h2 className='title-linethrough width-80'><span>{statistics_section.heading}</span></h2>
							}
							{
								validateArray(statistics_section.statistics) &&
								<div className='count-wrapper highlights-wrapper '>
									{
										statistics_section.statistics.map((item, index) => (
											<div key={index} className='count-col'>
												{
													item.count &&
													<>
														<div className='count-value'>
															<CountUp
																delay={(item.count && item.count < 100 ? 1 : 0)}
																duration={(item.count && item.count <= 1000 ? 2.8 : 2)}
																start={(item.count && item.count <= 30 ? 100 : 0)}
																end={(this.state.updateCount ? item.count : 1000)}
																suffix={item.count_suffix && " " + item.count_suffix}
																prefix={item.count_prefix && item.count_prefix + " "}
																separator={(item.hide_separator ? '' : ',') }
																className='count-value-visible'
															/>

															<CountUp
																delay={0}
																duration={0}
																start={item.count}
																end={item.count}
																suffix={item.count_suffix && " " + item.count_suffix}
																prefix={item.count_prefix && item.count_prefix + " "}
																separator={(item.hide_separator ? '' : ',')}
																className='count-value-hidden'
															/>
														</div>
													</>
												}
												{
													item.title &&
													<h5>{item.title}</h5>
												}
											</div>
										))
									}
								</div>
							}

						</div>
					</div>
				}
				{
					technologies_section &&
					(technologies_section.description || validateArray(technologies_section.technologies)) &&
					<div className='technologies box-80 has-top-border text-center'>
						<div className='container container--980'>
							{
								technologies_section.heading &&
								<h2 className='title-lineover width-80'><span>{technologies_section.heading}</span></h2>
							}
							{
								technologies_section.description &&
								<div className='description p__body1'
									dangerouslySetInnerHTML={{ __html: technologies_section.description }}
								/>
							}
							{
								validateArray(technologies_section.technologies) &&
								<TechnologiesStack
									data={technologies_section.technologies}
									className={'TechIcons__width130'}
								/>
							}
							{
								validateSingleLink(technologies_section.cta) &&
								<a className='btn btn-primary btn-rounded tech__CTA' href={technologies_section.cta.link}>
									{technologies_section.cta.title}
								</a>
							}

						</div>
					</div>
				}
				{
					development_examples &&
					validateArray(development_examples.case_studies) &&
					<CaseStudies
						data={development_examples.case_studies}
						heading={development_examples.heading}
						headingClass='title-linethrough width-80'
						className='box-80 has-top-border'
					/>
				}
				{
					clients_section &&
					validateArray(clients_section.clients) &&
					<div className='customers-section text-center box-80 has-top-border'>
						<div className='container'>
							{
								clients_section.heading &&
								<h2 className='title-linethrough width-80'><span>{clients_section.heading}</span></h2>
							}
							{
								validateArray(clients_section.clients) &&
								<CustomersSlider
									data={clients_section.clients}
									hasHover
									className='clients_section-slider container container--980'
									sliderSpeed={clients_section.slider_speed}
								/>
							}
						</div>
					</div>
				}
			</Layout >
		)
	}
}

export default ServiceDetailPage;
